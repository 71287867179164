import * as React from "react"
import { Grid, Row, Col } from "react-flexbox-grid"
import Recaptcha from "react-google-recaptcha"
import classNames from "classnames"
import * as buttonStyle from "../components/Button.module.css"

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY

if (typeof RECAPTCHA_KEY === 'undefined') {
  throw new Error(`
    Env var SITE_RECAPTCHA_KEY is undefined! 
    You probably forget to set it in your Netlify build environment variables. 
    Make sure to get a Recaptcha key at https://www.netlify.com/docs/form-handling/#custom-recaptcha-2-with-your-own-settings
    Note this demo is specifically for Recaptcha v2
  `)
}

const Contact = props => {

  const recaptchaRef = React.createRef()

  return (
    <Grid>
      <Row>
        <Col mdOffset={2} xs={10} md={3} className="row-margin-bottom">
          {props.children}
        </Col>
        <Col xs={10} md={5}>
          <form method="post" data-netlify-recaptcha="true" netlify-honeypot="bot-field" data-netlify="true" name="contact">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact" />
            <div className="form-item form-item-first">
              <label htmlFor="name" aria-label="Name"></label>
              <input type="text" className="textfield" id="name" name="name" placeholder="Name" />
            </div>
            <div className="form-item">
              <label htmlFor="email" aria-label="Email"></label>
              <input className="textfield" name="email" id="email" type="email" placeholder="Email" />
              </div>
            <div className="form-item form-item-last">
              <label htmlFor="enquiry" aria-label="Enquiry"></label>
              <input className="textarea" name="enquiry" id="enquiry" component="textarea" placeholder="Enquiry" />
            </div>
            <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} />
            <div className="form-item form-submit">
              <button className={classNames([buttonStyle.button, buttonStyle.buttonLarge, buttonStyle.buttonMargin, buttonStyle.buttonGold])} type="submit">Submit</button>
            </div>
          </form>
        </Col>
      </Row>
    </Grid>
  )
}

export default Contact